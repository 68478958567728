@tailwind base;
@tailwind components;
@tailwind utilities;

.loading-bar {
  height: 3px;
}

.loading-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 8px;
  background: #18c89b;
  box-shadow: 0 0 70px 25px #18c89b;
  clip-path: inset(0);
  animation: loading-bar-scan 1s ease-in-out infinite alternate,
    loading-bar-clip 2s ease-in-out infinite;
}

@keyframes loading-bar-scan {
  to {
    transform: translateY(-100%);
    top: 100%;
  }
}

@keyframes loading-bar-clip {
  33% {
    clip-path: inset(-250px 0 0 0);
  }
  50% {
    clip-path: inset(0 0 0 0);
  }
  83% {
    clip-path: inset(0 0 -250px 0);
  }
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 96px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
}

.spinner {
  position: relative;
  width: 32px;
  height: 32px;
  animation: spinner-rotate 2s linear infinite;
}

.spinner > div {
  position: absolute;
  left: 13px;
  width: 6px;
  height: 32px;
}

.spinner > div::before {
  content: "";
  display: block;
  background-color: rgba(255, 255, 255, 0.8);
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.spinner > div:nth-child(2) {
  transform: rotate(-45deg);
}

.spinner > div:nth-child(3) {
  transform: rotate(-90deg);
}

.spinner > div:nth-child(4) {
  transform: rotate(-135deg);
}

.spinner > div:nth-child(5) {
  transform: rotate(-180deg);
}

.spinner > div:nth-child(6) {
  transform: rotate(-225deg);
}

.spinner > div:nth-child(7) {
  transform: rotate(-270deg);
}

@keyframes spinner-rotate {
  to {
    transform: rotate(360deg);
  }
}
